import "@fontsource/lato";
import "@fontsource/baloo-2";

export const onRouteUpdate = ({ location }) => {
  const trackingId = window.GA_TRACKING_ID;

  if (!trackingId || typeof window.gtag !== `function`) {
    return;
  }

  let locationStr = "";

  if (location) {
    locationStr = `${location.pathname}${location.search}${location.hash}`;
  }

  const options = {
    page_path: locationStr,
    // anonymize_ip: true,
  };

  window.gtag("config", trackingId, options);
};
